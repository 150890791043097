@font-face {
  font-family: "SF Pro Display Regular";
  src: local('SFProDisplayRegular'), url(./fonts/SFProDisplayRegular.woff) format('woff');
}

.main-font {
  font-family: 'SF Pro Display Regular';
}

.main-font a {
  color: #1596b2;
}

.main-font a:hover {
  color: rgba(21, 150, 178, 0.8);
}

/* Custom btn color */
.ant-btn-primary {
  background: #1596b2 !important;
  border-color: #1596b2 !important;
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
  border-color: rgba(21, 150, 178, 0.8) !important;
  background: rgba(21, 150, 178, 0.8) !important;
}

.ant-btn-link {
  color: #1596b2 !important;
}

.ant-btn-link:hover{
  color: rgba(21, 150, 178, 0.8) !important;
}
/* Custom btn color end */

/* Custom swagger */
@media (max-width: 500px){
  /* modify api content */
  .swagger-ui .opblock-summary-control {
    flex-wrap: wrap !important;
  }
  .swagger-ui .opblock .opblock-summary-path {
    flex-shrink: 1 !important;
    max-width: none !important;
    min-width: 295px;
    max-width: 390px;
    padding: 6px 3px !important;
    font-size: 14px !important;
  }
  .swagger-ui .opblock .opblock-summary-description{
    padding-left: 3px;
  }
  .swagger-ui .authorization__btn {
    padding: 0 !important;
    margin-left: -15px !important;
  }
}
@media (max-width: 768px){
  .swagger-ui {
    margin-top: -20px;
  }
  .swagger-ui .info hgroup.main a {
    word-break: break-word;
  }
  .swagger-ui .info .title {
    font-size: 30px !important;
  }
  .swagger-ui .opblock-tag {
    font-size: 20px !important;
  }
  .swagger-ui .scheme-container {
    padding: 0 0 30px 0 !important;
  }
  .swagger-ui .scheme-container .schemes {
    display: block !important;
  }
  .swagger-ui .auth-wrapper {
    display: block !important;
    margin-top: 15px;
  }

  /* modify api content */
  .swagger-ui .opblock .opblock-summary-path {
    flex-shrink: 1 !important;
    max-width: none !important;
  }
  .swagger-ui .arrow {
    max-width: 10px;
  }
  
  /* modify api content */
  /* Hide Responses link */
  .swagger-ui .response-col_links {
    display: none !important;
  }
  /* Hide Responses link */
}
/* Custom swagger */
