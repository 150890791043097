.ant-notification-notice {
  border-radius: 20px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.16);
  padding: 28px 32px;
}
.ant-notification-notice-message, .ant-notification-notice-description {
  color: #4c4c4d;
  font-size: 18px;
}
.ant-notification-notice-message {
  font-weight: 900 !important;
}
.ant-notification-close-x {
  font-size: 24px;
}