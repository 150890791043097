.ant-message-notice-content {
  width: 190px;
  height: 50px;
  font-size: 18px;
  border-radius: 8px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #f5f5f5;
}
.ant-message-notice.no-icon .anticon{
  display: none;
}
